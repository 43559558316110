<template>
  <div>
    <v-card class="mb-3">
      <v-card-title>Dashboard</v-card-title>
    </v-card>

    <v-row>
      <v-col md="3" cols="12">
        <v-card class="p-5" elevation="2" outlined tile>
          <v-card-title>SERP Total:</v-card-title>
          <v-card-subtitle>{{ countSerp }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <v-tabs class="mt-3">
      <v-tab>SERP Reports</v-tab>
    </v-tabs>

    <v-card v-if="showSERP">
      <v-card-text>
        <v-progress-circular indeterminate color="primary" v-if="loadingSERP"></v-progress-circular>
        <v-skeleton-loader v-if="loadingSERP == true"></v-skeleton-loader>
        <v-data-table
          :headers="headers"
          :items="serp"
          item-key="id"
          class="elevation-0 border-1"
          no-data-text="No data"
          no-results-text="No results"
          :items-per-page="items"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, 20, 25, 30],
            'items-per-page-text': 'Rows',
            'page-text': '',
          }"
          v-if="serp.length > 0 && loadingSERP == false"
          :key="dataTableKeySERP"
          mobile-breakpoint="0"
        >
          <template v-slot:item.type="{ item }">
            {{ item.type == 1 ? 'Paid' : 'Organic' }}
          </template>
          <template v-slot:item.id="{ item }">
            <a @click="$router.push('/serp-analysis/report/' + item.id)">{{ item.id }}</a>
          </template>
          <template v-slot:item.compurl="{ item }">
            <a :href="item.compurl" target="blank">{{ item.compurl }}</a>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon small @click="setDeleted(item)"><v-icon>mdi-trash-can</v-icon></v-btn>
          </template>
        </v-data-table>

        <v-alert icon="mdi-information" prominent text v-else-if="serp.length == 0 && loadingSERP == false">
          No data available
        </v-alert>

        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5"> Delete Report {{ selectedDelete.id }}? </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false"> Cancel </v-btn>
              <v-btn color="green darken-1" text @click="deleteRep(selectedDelete)"> Delete </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>

  </div>
</template>


<script>
import axios from 'axios'
import { computed } from 'vue-demi'
import { mapActions } from 'vuex'
import * as moment from 'moment'

export default {
  data() {
    return {
      reports: [],
      loadingSERP: true,

      items: 10,
      dataTableKeySERP: 0,

      fullReports: [],
      semantics: [],
      serp: [],
      selectedDelete: [],
      dialog: false,
      selectedTab: 1,

      showSERP: true,
      countSerp: 0,

      headers: [
        {
          text: 'User',
          align: 'start',
          value: 'user.email',
        },
        {
          text: 'Report ID',
          align: 'start',
          value: 'id',
        },
        {
          text: 'Report Name',
          align: 'start',
          value: 'report_name',
        },
        {
          text: 'Keyword',
          align: 'start',
          value: 'keyword',
        },
        {
          text: 'Compare Domain',
          align: 'start',
          value: 'compurl',
        },
        {
          text: 'When',
          align: 'start',
          value: 'created_at',
        },
        { text: 'Actions', value: 'action', sortable: false, width: '100' },
      ],
    }
  },
  methods: {
    ...mapActions({
      getAll: 'keyword_research/historyAll',
      getSemantics: 'semantic_analysis/getSemanticAnalysisHistoryAll',
      getSERPS: 'serp_analysis/getReportsAll',
      serpCount: 'serp_analysis/getReportsCount',
    }),
    setDeleted(item) {
      this.dialog = true
      this.selectedDelete = item
    },
    getSerpCount() {
      this.serpCount()
        .then(resp => {
          this.countSerp = resp.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSERP() {
      this.getSERPS()
        .then(resp => {
          this.serp = resp.data
          this.loadingSERP = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    formatDate(value) {
      return moment(value, 'YYYYMMDD').fromNow()
    },
  },
  mounted() {
    if (localStorage.getItem('userData')) {
      this.getSERP()
      this.getSerpCount()
      this.dataTableKeySERP += 1
      if (localStorage.getItem('itemsPerPage')) {
        this.items = parseInt(localStorage.getItem('itemsPerPage'))
      }
    } else {
      this.$router.push({ name: 'auth-login' })
    }
  },
}
</script>

