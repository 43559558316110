var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-3"},[_c('v-card-title',[_vm._v("Dashboard")])],1),_c('v-row',[_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('v-card',{staticClass:"p-5",attrs:{"elevation":"2","outlined":"","tile":""}},[_c('v-card-title',[_vm._v("SERP Total:")]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.countSerp))])],1)],1)],1),_c('v-tabs',{staticClass:"mt-3"},[_c('v-tab',[_vm._v("SERP Reports")])],1),(_vm.showSERP)?_c('v-card',[_c('v-card-text',[(_vm.loadingSERP)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.loadingSERP == true)?_c('v-skeleton-loader'):_vm._e(),(_vm.serp.length > 0 && _vm.loadingSERP == false)?_c('v-data-table',{key:_vm.dataTableKeySERP,staticClass:"elevation-0 border-1",attrs:{"headers":_vm.headers,"items":_vm.serp,"item-key":"id","no-data-text":"No data","no-results-text":"No results","items-per-page":_vm.items,"footer-props":{
          'items-per-page-options': [5, 10, 15, 20, 25, 30],
          'items-per-page-text': 'Rows',
          'page-text': '',
        },"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.type == 1 ? 'Paid' : 'Organic')+" ")]}},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.$router.push('/serp-analysis/report/' + item.id)}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.compurl",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":item.compurl,"target":"blank"}},[_vm._v(_vm._s(item.compurl))])]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.setDeleted(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)}):(_vm.serp.length == 0 && _vm.loadingSERP == false)?_c('v-alert',{attrs:{"icon":"mdi-information","prominent":"","text":""}},[_vm._v(" No data available ")]):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Delete Report "+_vm._s(_vm.selectedDelete.id)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.deleteRep(_vm.selectedDelete)}}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }